1. By visiting, viewing, accessing, browsing, surfing, making any purchase or using our website audit.soy including its sub-domains, website pages, hyperlinks or mobile optimized version, if any (“Website”) and/or using any of our Website Services (as defined in Terms and Conditions), you (hereinafter referred to as “you”, “your” or “User”) accept our following Cookies Policy:

    A. We use technologies such as cookies to make better users experience, customise content, to provide social media features and to analyse traffic to the Website. Where applicable, the Website uses a cookie control system allowing the user on their first visit to the Website to allow or disallow the use of cookies on their computer / device.

2. Cookies are small files saved to the user's computers’ or mobile devices’ hard drive or memory that track, save and store information about the user's interactions and usage of the Website. This allows the Website, through its server to provide the users with a tailored experience within the Website.

    A. Cookies are small files saved to the user's computers’ or mobile devices’ hard drive or memory that track, save and store information about the user's interactions and usage of the Website. This allows the Website, through its server to provide the users with a tailored experience within the Website.

3. You are advised that if you wish to deny the use and saving of cookies from the Website on to your device’s memory / hard drive, you should take necessary steps within your web browsers security settings to block all cookies from the Website and its external serving vendors.

    A. We may gather certain information automatically and store it in log files. This information includes Internet protocol (IP) addresses, browser type, Internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and click stream data. We may use this information, which does not identify your individual identity, to analyse trends, to administer the Website, to track your movements around the Website and to gather demographic information about our user base as a whole.

