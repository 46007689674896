import privacyPolicy from "./md/privacy-policy.md";
import cookiePolicy from "./md/cookie-policy.md";
import tos from "./md/terms-of-service.md";
import disclaimer from "./md/disclaimer.md";

// on scroll make navbar background visible
window.addEventListener("scroll", () => {
  const navbar = document.getElementById("navbar");
  if (window.scrollY > 10) {
    navbar!.classList.remove("transparent");
  } else {
    navbar!.classList.add("transparent");
  }
});

window.onload = () => {
  const formEl = document.getElementById("contact-form");
  if (formEl) {
    formEl.addEventListener("submit", (ev) => {
      ev.preventDefault();
      const formData = new FormData(formEl as HTMLFormElement);
      const data = {
        name: formData.get("name"),
        sender: formData.get("email"),
        whatsapp: formData.get("whatsapp"),
        message: formData.get("help"),
        source: 'Audit Soy'
      };
      fetch("https://contact-email.dotcomltd.workers.dev/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status === 200) {
            formEl.innerHTML = `
            <div class="alert alert-success" role="alert">
              Your message has been sent successfully!
            </div>
            `;
          } else {
            formEl.innerHTML = `
            <div class="alert alert-danger" role="alert">
              There was an error sending your message. Please try again later.
            </div>
            `;
          }
        })
        .catch((err) => {
          formEl.innerHTML = `
          <div class="alert alert-danger" role="alert">
            There was an error sending your message. Please try again later.
          </div>
          `;
        });
    });
  }
  const pp = document.getElementById("privacy-policy");
  const cp = document.getElementById("cookie-policy");
  const tosEl = document.getElementById("terms-of-service");
  const disclaimerEl = document.getElementById("disclaimer");
  if (pp) {
    pp.innerHTML = privacyPolicy;
  }
  if (cp) {
    cp.innerHTML = cookiePolicy;
  }
  if (tosEl) {
    tosEl.innerHTML = tos;
  }
  if (disclaimerEl) {
    disclaimerEl.innerHTML = disclaimer;
  }

  // full page mobile menu on click
  const menu = document.getElementById("mobile-nav");
  const desktopMenuItems = document.getElementById("nav-desktop");
  const mobileMenuItems = document.getElementById("mobile-nav-links");
  const menuOpen = document.getElementById("mobile-nav-open");
  const menuClose = document.getElementById("mobile-nav-close");
  menuClose!.addEventListener("click", (ev) => {
    menu!.classList.remove("slide-in-from-left");
    menu!.classList.add("slide-out-to-left");
    setTimeout(() => {
      menu!.classList.add("d-none");
    }, 500);
  });
  menuOpen!.addEventListener("click", () => {
    // select all links with href of links without #
    const links = desktopMenuItems!.querySelectorAll("a:not([href='#'])");
    // add these links to mobile menu
    links.forEach((link) => {
      link.classList.value = "d-block nav-link text-white me-3 mb-4 active";
      mobileMenuItems!.appendChild(link);
    });
    // fade in mobile menu while removing d-none class
    menu!.classList.remove("slide-out-to-left");
    menu!.classList.remove("d-none");
    menu!.classList.add("slide-in-from-left");
  });

  const url = window.location.pathname;
  if (url === "/" || url === "/index.html") {
    document.getElementById("nav-desktop")!.children[0].classList.add("active");
  } else if (url.includes("about")) {
    document.getElementById("nav-desktop")!.children[1].classList.add("active");
  } else if (url.includes("faq")) {
    document.getElementById("nav-desktop")!.children[2].classList.add("active");
  } else if (url.includes("contact")) {
    document.getElementById("nav-desktop")!.children[3].classList.add("active");
  }
};
