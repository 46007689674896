By accessing or using audit.soy (the “Website”), a website owned and maintained by Dotcom Ventures FZE (“Company”, “we,” “us” or “our”), you consent to the information collection, disclosure and use practices described in this Privacy Policy.
The Website is an independent venture of the Company. Its purpose is to offer Smart Contract Audit services. Audit.soy is a trusted, independent and professional audit service provider for smart contracts, which highlights vulnerabilities and recommend remedies to help keep your Web3 project safe, secure and on track. The audit services are provided using best-in-class Formal Verification processes and AI technology, whereby, our expert team hunts down design vulnerabilities in smart contracts before they cause technical issues. The audit process involves three steps: Identification – Remediation – Verification. Audit.soy helps make the Web3 world a safer place. We believe in mitigating the risks of the rug-pulling, hacking and scamming that continues to tarnish the crypto space as we always value honesty as the best policy. We perform automated Testing tools to simulate interactions and uncover common vulnerabilities and manual testing for line-by-line manual testing to highlight less frequent vulnerabilities. After that we our experts recommend solutions by flagging areas of concern according to their nature and accordingly suggest critical, major, medium and low-risk solutions to achieve maximum security. Lastly, an easy-to-read smart contract Audit Report is generated containing all issues, risks and suggested remedies (the “Services”).
This Privacy Policy sets out how we may collect, use and disclose information in relation to users of the Website and Services. Use of Website or the Services provided by the Company is subject to separate Terms and Conditions, applicable disclaimers and other regulations/policies/notices/agreements as well as the following Privacy Policy.


1. Information We Collect
A. Your privacy is important to us and we have taken steps to ensure that we do not collect more information from you than what is necessary.
B. All our subsidiary companies have separate Privacy Policies for their respective services.
C. Consent Rule: If you have given consent to the processing of your data, you can freely withdraw such consent at any time by emailing the Company at mydata@dotcom.ltd.
D. If you do withdraw your consent, and if the Company does not have another legal basis for the processing of your data, then the Company will stop the processing of your personal data.
E. If the Company has another legal basis for the processing of your data (legitimate Interests, for example), then the Company may continue to do so subject to your legal interests and rights.
F. We may collect, use and process the following information (“Data”) about you:
G. When you use the Website or its Services, the following types of Personal Data, Payment and Invoicing Data, H. Communication Data, Technical Data and Marketing Data (collectively the “Data”) may be collected from you:
I. Personal Data: Any data/legal documents as may be deemed necessary for providing the Services including but not limited to contact details such as your email address, and contact number etc. When you e-mail us, your e-mail address may be added to our mailing list from which you can unsubscribe at any time using the unsubscribe link in each e-mail or by contacting us at legal@dotcom.ltd.
J. Communication Data: Includes data that you provided us through the contact form on the Website, through e-mail or otherwise. The lawful ground for this processing is our legitimate interests such as to reply to communications sent to us, to keep records and to establish, pursue or defend potential legal claims.
K. Technical Data: Technical Data includes data regarding your use of the Website and the Services such as your IP address, login, browser details, details of visit of pages on the Website, page views and navigation details, usage amount, time zone settings and other technical information on your device. The lawful ground for this processing is our legitimate interests such as to enable us to properly administer the Website and our business and to grow our business.
L. Marketing Data: Includes data about your preferences in receiving marketing material from us and our third parties. The lawful ground for this processing is our legitimate interests such as to study how Users use the Services, to develop them, to grow our business and to decide our marketing strategy.
How We Use and Process the Data
Your Data may be used to provide you the Services and better understand your needs in relation thereto, to reply to any of your questions and requests regarding the Services and to provide required Services to Users.
Your Data is not rented or sold to others.
You are deemed to have authorized us to use your Data for the following purposes:
processing transactions;
verifying your identity;
providing customer support and responding to your queries, feedback or disputes and notifying you of any changes to the service, solving issues, any bug fixing;
making such disclosures as may be required for any of the above purposes or as required by applicable laws and regulations or in respect of any investigations, claims or potential claims brought on or against us;
sending you notices regarding services you are receiving and for billing and collection purposes;
providing and maintaining the Services (the Company needs to provide services accessible via the Websites);
improving the Website such as through personalized features and content (testing features, interacting with feedback platforms and questionnaires, managing landing pages, heat mapping the Websites, traffic optimization and data analysis and research, including profiling and the use of machine learning and other techniques over your data and in some cases using third parties to do this);
Legal purposes such as Consent; Contract; and Legitimate Interests;
Keeping the Websites running: (managing your requests (like orders), login and authentication, remembering your settings, processing payments, hosting and back-end infrastructure); and
Marketing and analytical purposes with your consent (sending you emails and messages about new functions, goods and services, and content)
It shall be ensured that:
Your Data collected by us or any of our third party will be used as per applicable data privacy laws;
The reasons for the collection of Data and its usage will always be made known to you;
Only the necessary Data will be collected from you;
Cookies or similar technologies will be used in accordance with applicable laws;
If any Data submitted by you online cannot be fully deleted at your request under normal circumstances, you will be informed accordingly;
Necessary technical and organizational measures are used to protect your Data; and
Your Data is transferred securely.
Disclosure of Data
We shall not be able to keep your Data private in response to legal process i.e., a court order or a subpoena, a law enforcement agency’s request. If, in our view, it is deemed appropriate to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our terms of use, or as otherwise required by law, we may be compelled to disclose the Data and Personal Data. Moreover, in case of takeover, merger or acquisition, we reserve a right to transfer your data to new platform.
We may disclose the Data in the good faith belief that such action is necessary to:
comply with a legal obligation
protect and defend our rights or property
prevent or investigate possible wrongdoing
protect the personal safety of users of the Service or the public
protect against legal liability

When necessary, we may also disclose and transfer your Data to our professional advisers, law enforcement agencies, insurers, government and regulatory and other organizations.
Data Storage and Transfer
We will store and process your Data at our dedicated server which is secured with firewalls and other securities. Coding files and other data collected on the Website from Users are stored in our database.
We act as the controller and our above partners as processors, meaning they will not undergo Personal Data processing activities towards information registered, submitted or conveyed by us.
Your Data may be transferred to our affiliated entities or other third-parties across borders. You, hereby, give consent to such transfer.
Your Data will be retained for as long as necessary to fulfil the purposes we collected it including to satisfy any record retention or reporting requirements under applicable laws.
As herein above mentioned, we will maintain Personal Data pertaining to our Users for the duration of the Services and as per legal requirements.
Protection of your Data
We store all the Data submitted by you through Website at a secure database.
We are concerned with protecting your privacy and data, but we cannot ensure or warrant the security of any data you transmit to or guarantee that your Data may not be accessed, disclosed, altered or destroyed by breach of any of our industry standard physical, technical or managerial safeguards.
No method of transmission over the Internet or method of electronic is secure. You acknowledge that no perfect security infrastructure exists, no data transmission is guaranteed to be 100% secure, and there may be some security risks.
Therefore, we cannot guarantee its absolute security. If you have any questions about security of our Website, you can contact us at security@dotcom.ltd.
Any Data supplied by you will be retained by us and will be accessible by our employees, any service providers engaged by us and third parties.
We use servers that comply with strict international data security standards.
You are responsible for your login information and password. You shall keep them confidential.
In case if your privacy has been breached, please contact the Company immediately on privacy@dotcom.ltd.
The rights of Users
You may exercise certain rights regarding your Data processed by us. In particular, you have the right to do the following:
Withdraw consent at any time. You have the right to withdraw consent where you have previously given your consent to the processing of your Data.
Object to processing of Data. You have the right to object to the processing of your Data if the processing is carried out on a legal basis other than consent.
Access to the Data. You have the right to learn if Data is being processed by us, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.
Verify and seek rectification. You have the right to verify the accuracy of your Personal and other Data and ask for it to be updated or corrected.
Restrict the processing of your Data. You have the right, under certain circumstances, to restrict the processing of Data. In this case, we will not process your Data for any purpose other than storing it.
Have the Data deleted or otherwise removed. You have the right, under certain circumstances, to obtain the erasure of the Data from us.
You may initiate request with us at mydata@dotcom.ltd to exercise any of the above mentioned rights. We shall review your request and, in our own discretion, honor your request, if deemed necessary by us, within reasonable time.
Compliance with the GDPR
We will make all reasonable efforts to ensure that the Website complies with the General Data Protection Regulation (GDPR) (EU) 2016/679 of the EU regarding the collection, use and retention of Data from the Users based in European Union’s Member States. We will also make all reasonable efforts to adhere to the GDPR requirements of notice, choice, onward transfer, security, data integrity, access and enforcement.
We strictly observe Article 14 ruling of the GDPR, making explicitly known to the user which Personal Data we have gathered pertaining to him / her and what has been the source as well as the “purpose” and “scope” of our Personal Data processing activities.
Per Article 14 of the GDPR, if either the user provides no feedback or he/she declines to approach / contact, we shall erase the Personal Data that we have gathered until one (1) month after collection date.
To prevent further contact within the same scope, the user’s name and e-mail address will be ‘blacklisted’ in our records that is accessible to our relevant internal departments only.
Upon the collection of Personal Data pertaining to Users, we will identify the User by asking further information, if required.
The Rights of Users: Users based in EU may exercise following rights regarding their Data:
(i) Right of confirmation: Users shall have the right to obtain confirmation from us as to whether or not your Personal Data is being processed.
(ii) Right of Access: Users shall have the right to obtain free information from us about their Personal Data as well as its copy at any time. The EU directives and regulations also grant you access to the following information:
the purpose of the processing;
the categories of Personal Data concerned;
the recipients or types of recipients to whom your Personal Data have been or will be disclosed, in particular recipients in third countries or international organizations;
the envisaged period for which your Personal Data will be stored, or, if that is not possible, the criteria used to determine that period;
the right to request from us rectification or erasure of Personal Data or restriction of processing of your Personal Data or to object to such processing;
the right to lodge a complaint with a supervisory authority;
where your Personal Data is not collected, any available information as to its source;
the existence of automated decision-making, including profiling, referred to in Articles 22(1) and (4) of the GDPR and, at least in those cases, meaningful information about the logic involved as well as the significance and envisaged consequences of such processing for you.
(iii) Right to rectification: Users shall have the right to obtain from us without undue delay the rectification of their inaccurate Personal Data. Taking into account the purposes of the processing, Users shall have the right to have incomplete Personal Data completed, including by means of providing a supplementary statement.
(iv) Right to be forgotten: Users shall have the right to require us to erase their Personal Data without delay which we will be obliged to do so where one of the following grounds applies as long as the processing is not necessary:
The Personal Data is no longer required.
You choose to withdraw your consent as per sub-article (a) of Article 6(1) of the GDPR or point (a) of Article 9(2) of the GDPR.
As per Article 21(1) of the GDPR, you raise any objection against the processing of your Data and when there is no overriding ground for such processing or you raise objection pursuant to Article 21(2) of the GDPR.
The Personal Data has been unlawfully processed.
The Personal Data must be erased for compliance with a legal obligation in EU or a Member State law to which we are subject.
The Personal Data have been collected in relation to the offer of information society services referred to in Article 8(1) of the GDPR.
Where we have made Personal Data public and are obliged pursuant to Article 17(1) to erase such Personal Data, we, while taking account of available technology and the cost of implementation, shall take reasonable steps including technical measures to inform other controllers processing the Personal Data that you have requested the erasure by such controllers of any links to, or copy or replication of, those personal data, as far as processing is not required. We will arrange the necessary measures in individual cases.
(v) Right of restriction of processing: User shall have the right to obtain from us restriction of processing where one of the following applies:
The accuracy of the Personal Data is contested by the User for a period enabling us to verify the accuracy of the Personal Data.
The processing is unlawful and the User opposes the erasure of the Personal Data and requests instead the restriction of their use.
We no longer need the Personal Data for the processing but we are required by the User for the establishment, exercise or defence of legal claims.
Pursuant to Article 21(1) of the GDPR you exercise your right to object to processing when the verification process is pending.
If any of the above conditions is fulfilled and you want to restrict processing of your Personal Data stored by us, you may contact us at mydata@dotcom.ltd.
(vi) Right to data portability: Users shall have the right to receive their Personal Data, which was provided to us, in a structured, commonly used and machine-readable format. Users shall also have the right to transmit such data to another data controller if such processing is based on consent retrieved as per point (a) of Article 6(1) of the GDPR or point (a) of Article 9(2) of the GDPR or on a contract pursuant to point (b) of Article 6(1) of the GDPR and the processing is carried out by automated means as long as the processing is not necessary for the performance of a task carried out in the public interest or the exercise of official authority vested in us.
(vii) Right to object: Users shall have the right to object on grounds relating to their particular situation at any time to processing of their Personal Data, which is based on point (e) or (f) of Article 6(1) of the GDPR.
The Personal data will no longer be processed in the event of the objection unless we can demonstrate compelling legitimate grounds for the processing.
If a User’s Personal Data is processed for direct marketing purposes, that User shall have the right to object at any time to processing of his / her Personal Data for such marketing. This applies to profile to the extent that it is related to such direct marketing. If a User objects to us to the processing for direct marketing purposes, we will no longer process the Personal Data for these purposes.
A User shall have the right, on grounds relating to his / her particular situation, to object to the processing of his / her Personal Data for scientific or historical research purposes or statistical purposes pursuant to Article 89(1) of the GDPR, unless the processing is necessary for the performance of a task carried out for reasons of public interest.
Right to withdraw data protection consent: Users shall have the right to withdraw their consent to the processing of their Personal Data at any time. Users may initiate a request with us at mydata@dotcom.ltd to exercise any of the above-mentioned rights. We shall review your request and, in our discretion, honour your request, if deemed necessary by us, within a reasonable time. Personal Data pertaining to a former Users shall be erased from our records once all applicable legal timelines have expired being that if those are dilated in time (meaning over 1 year after service termination), the Personal Data shall be securely segregated from live Data.
Compliance with CalOPPA
We comply with the California Online Privacy Protection Act (“CalOPPA”) which requires any person or company in the United States (and conceivably the world) that requires websites collecting ‘Personally identifiable information’ from California based users to upload a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared. Under CalOPPA, we agree to the following:
You can visit our site anonymously.
Our Privacy Policy link includes the word ‘Privacy’ and can easily be found on the page specified above.
We endeavour to fulfil requirements of the CalOPPA under this Privacy Policy in line with our policy for user data collection, storage, and disclosure as outlined for GDPR compliance.
Compliance of California Privacy Act 2018
This Privacy Policy is also applicable on California residents and explains how we collect, use and share your Personal Information and how you may exercise your rights under the California Consumer Privacy Act of 2018 (“CCPA”).
Your rights under the CCPA:
Under the CCPA, Users have the right to access their Personal Data during the past twelve (12) months and information about our data practices. Users also have the right to request that we delete their Personal Data.
To request manual access or deletion of your Personal Data, please contact us at mydata@dotcom.ltd.
Please note that for making manual requests you will need to verify your identity by providing us with all the information as we may require from you for this purpose.
You have the right to be free from any discrimination for exercising your rights to access or delete your Personal Information.
If you have additional questions about this or how to exercise your rights under the CCPA, please contact us at legal@dotcom.ltd.
Third-Party Service Providers
Third party companies and individuals may be employed to facilitate operational and voice communication services ("Third Party Service Providers") in relation to providing the Services on our behalf, to perform Services-related services or to assist us in analysing how the Service is used.
These third parties have access to your Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
Advertising Partners Privacy Policies
You may need to agree the privacy policy for each of the advertising partners of the Website.
Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on the Website, which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
Note that we do not have any access or control over these cookies that are used by third-party advertisers.
Third Party Links
The Website may contain links to third-party websites, plug-ins and applications. Except as otherwise discussed in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you on our Website. Other websites accessible through our site via links or otherwise have their own policies in regard to privacy. We are not responsible for the privacy policies or practices of third parties. When you leave our Website, we encourage you to read the privacy notice of every website you visit.
Security
The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
Changes to this Privacy Policy
We may modify these this Privacy Policy from time to time, and any such change shall be reflected on the Website with the updated version of the Privacy Policy and you agree to be bound to any changes to the updated version of Privacy Policy when you use the Website.
You acknowledge and agree that it is your responsibility to review this Website and this Policy periodically and to be aware of any modifications. Updates to this Policy will be posted on this page.
Also, occasionally there may be information on the Website that contains typographical errors, inaccuracies or omissions that may relate to service descriptions, pricing, availability, and various other information, and the Website reserves the right to correct any errors, inaccuracies or omissions and to change or update the information at any time, without prior notice.
Contact US
If you have questions about our Privacy Policy, please contact us via email: privacy@dotcom.ltd.